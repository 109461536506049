export const environment = {
  production: false,
  Settings: {
    WEB_TARGET_API: 'https://ti-discovery-api-dev.azurewebsites.net/',
    APPLICATION_INSIGHTS_INSTRUMENTATION_KEY: '',
    azureConnection: 'https://noti5processor.azurewebsites.net/api/',
    WEB_LANGUAGE: 'en',
    WEB_TITLE: 'TI-Discovery',
    VERSION: '1.0.0.0',
    SITE_KEY: '6LfjnjcdAAAAACcYQEzwsECVECbZeySbR45_wEx4',
    accordion_count: 50,
    OktaIssuer:"https://b2b-transportationinsight.okta.com/oauth2/default", // 'https://dev-92837008.okta.com/oauth2/default',
    OktaClientId: '0oa4pvuu0xeg2wfRf4x7',//"0oa2h041ffKsIcLM95d7",
    OktaRedirectURI: window.location.origin + '/login/callback',
    MAX_UPLOAD_SIZE: 367001600, //350MB
    FileSize: 26214400,
    EnableMVPItems:false,
    StorageContainerFolderName: "StandardTemplates",
    WhitepapersStorageFolderName: "WhitePapers",
    TableauUserName: "discovery-app",
    TableauBaseUrl: "https://dtitableau.t-insight.com/trusted/",
    TableauWorkBookAndViewPart: "/t/Discovery/views/OpportunityDashboard/Opportunity",
    IdleTime: 1680,//in sec
    WarningTime: 120, //in sec
    techsupportcontact: ' XXX-XXX-XXXX',
    techsupportmail: 'support-email@XXXX.com',
    salessupportcontact: 'XXX-XXX-XXXX',
    salessupportmail: 'sales-email@XXXX.com'
  },
};


